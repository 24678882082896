import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,

} from 'reactstrap';



import logo from '../assets/images/logo.png';
import fb_icon from '../assets/svg/fb-ico.svg';
import instagram_icon from '../assets/svg/instagram-ico.svg';
import twitter_icon from '../assets/svg/twitter-ico.svg';
import location_icon from '../assets/svg/location-ico.svg';
import phone_icon from '../assets/svg/phone-ico.svg';
import mail_icon from '../assets/svg/mail-ico.svg';
import viber from '../assets/svg/viber.svg';

export class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <footer className="footer">
                <Container>

                    <Row className="footer-top">
                        <Col md="3">
                            <div className="logo">
                                <img src={logo} />
                            </div>
                        </Col>
                        <Col md="9">
                            <ul className="navigation">
                                <li><Link to='/'>Početna</Link></li>
                                <li><Link to='/contact'>Proizvodi</Link></li>
                                <li><Link to='/contact'>O nama</Link></li>

                                <li><Link to='/contact'>Blog</Link></li>
                                <li><Link to='/contact'>Kontakt</Link></li>


                            </ul>

                        </Col>
                    </Row>
                    <Row>


                        <div className="spacer"></div>


                        <Col lg="3" className="social">
                            Pratite nas
                         <a href="https://www.facebook.com/volimoprirodno.webshop/" target="_blank"><Isvg src={fb_icon} /></a>
                            <a href="#" ><Isvg src={twitter_icon} /></a>

                            <a href="https://www.instagram.com/volimoprirodno/ " target="_blank"><Isvg src={instagram_icon} /></a>

                        </Col>
                        <Col lg="9" className="contact-container">
                            <div>
                                <Isvg src={location_icon} /> Kočine bb, 88000 Mostar, BiH
                                </div>
                            <div>
                                <Isvg src={phone_icon} /> +387 61 683 493
                                </div>
                            <div className="viber">
                                <a href="viber://add?number=%2B38761683493"><Isvg src={viber} /> +387 061 683 493</a>
                            </div>


                            <div>
                                <Isvg src={mail_icon} /> info@volimoprirodno.net
                                </div>
                        </Col>
                        <div className="spacer"></div>


                        <div className="copyright">
                            <p>Copyright © Volimo prirodno  - 2019. All Rights Reserved.</p>
                            <p>Created by <span><a href="https://www.novamedia.agency">NOVA media</a></span></p>
                        </div>

                    </Row>
                </Container>

            </footer>
        )
    }
}

export default Footer;