import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import plus_icon from '../assets/svg/plus-icon.svg';
import minus_icon from '../assets/svg/minus-icon.svg';

import wishlistIcon from '../assets/svg/wishlist-icon.svg';

import image from '../assets/images/no-image.jpg';
import Slider from "react-slick";
import titleLeafs from '../assets/svg/title-leafs.svg'


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


class DetailPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.addToWishlist = this.addToWishlist.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);

        this.state = {
            attributes: [],
            variations: [],
            product: {},

        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }


    generateBreadcrumb(items, breadcrumb, level) {
        if (breadcrumb[0] == "/") {
            breadcrumb = breadcrumb.substr(1);
        }
        let broken = breadcrumb.split("/");
        let arr = [];

        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            console.log(broken, items[i], level);
            if (broken[level] == items[i].Alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].Name,
                    link: "/category/" + items[i].Alias
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        //console.log(this.props[0]);
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.setState({
                product: {},
            }, () => {
                this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });
            });
        }

        if (!this.props.breadcrumb.length && this.props.categories.length && this.state.product && this.state.product._id && this.state.product.Breadcrumb && !this.state._updatingBreadcrumb) {
            let breadcrumb = this.generateBreadcrumb(this.props.categories, this.state.product.Breadcrumb, 0);


            this.setState({
                _updatingBreadcrumb: true
            })


            if (breadcrumb){
                let state = {};

                for(let i=0;i<breadcrumb.length;i++){
                    state['_selectedCategory' + i] = breadcrumb[i]._id;
                }
                this.setState(state);
            }

            console.log(breadcrumb);
            this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchProduct', (data) => {
            console.log(data);
            this.setState({
                product: data,
                previewImage: data.Images && data.Images.length && data.Images[0],
                package: data.package && data.package[0],
                quantity: data.MinOrder
            });
        });


        this.props.socketIOClient.on('addToCart', (data) => {
            console.log(data);
            if (data.successful) {
                this.props.showInfoMessage(this.props.translate('Artikal je uspješno dodat u korpu.'));
                this.props.socketIOClient.emit('cartInfo', {});
            } else {
                this.props.showInfoMessage(this.props.translate('Nema dovoljno na stanju.'), true);

            }
        });

        this.props.socketIOClient.on('addToWishlist', (data) => {
            console.log(data);
            this.props.showInfoMessage(this.props.translate('Artikal je dodat u listu omiljenih.'));

        });




        this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });


    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchProduct");
        this.props.socketIOClient.removeAllListeners("addToWishlist");
        this.props.socketIOClient.removeAllListeners("addToCart");

    }



    addToWishlist() {
        if (!this.props.uData) {
            this.props[0].history.push('/login');
            return;
        }

        this.props.socketIOClient.emit('addToWishlist', { _id: this.props[0].match.params.id });
    }


    addToCart() {
        this.props.socketIOClient.emit('addToCart', { _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity), package: this.state.package });

    }


    onTouchStart(event) {
        var x = event.clientX;
        var y = event.clientY;
        if (!this.state._startSwipePos) {
            this.setState({
                _startSwipePos: x,
                _startSwipePosY: y,
                _startLeft: this.carousel.scrollLeft
            });
        }
    }

    onTouchEnd() {
        this.setState({
            _startSwipePos: null,
            _startSwipePosY: null,
            _startLeft: null
        });
    }

    onTouchMove(event) {
        var x = event.clientX;
        var y = event.clientY;

        if (this.state._startSwipePos) {
            this.carousel.scrollLeft = this.state._startLeft - (x - this.state._startSwipePos);
        }

        this.setState({
            _swipePos: x
        });


    }


    render() {
        let product = this.state.product;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth < 768 ? 2 : 4,
            slidesToScroll: 1
        };

        return (
            <div className="detail-wrap">

                <Container>
                    <Row>
                        <Col lg="3" className="d-none d-lg-block">
                            <div className="categories">
                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />

                            </div>
                        </Col>
                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                            <li><Link to='/category'>Kategorija</Link></li>
                                            {
                                                this.props.breadcrumb.map((item, idx) => {
                                                    if (item)
                                                        return (
                                                            <li key={idx}><Link to={item.link}>{item.name}</Link></li>

                                                        )
                                                })
                                            }
                                        </ul>
                                    </Col>
                                    <Col lg="5">
                                        <h1>{this.props.breadcrumb.length && this.props.breadcrumb[this.props.breadcrumb.length - 1] ? this.props.breadcrumb[this.props.breadcrumb.length - 1].name : "KATEGORIJE"}</h1>
                                    </Col>
                                    <Col lg="7">
                                    </Col>
                                    <Col lg="12">
                                        <Container className="article-box">
                                            {product.discount ?
                                                <div className="discount-detail">{product.discount}%</div>
                                                :
                                                null
                                            }

                                            <Row>
                                                <Col lg="" className="article-image">

                                                    <img className="preview" onError={() => { this.setState({ imageError: true }) }} src={!this.state.imageError ? this.state.previewImage ? this.state.previewImage : image : image} />

                                                    <div className="images" onMouseDown={this.onTouchStart} onMouseMove={this.onTouchMove} onMouseUp={this.onTouchEnd} ref={(input) => { this.carousel = input; }}>


                                                        {
                                                            product && product.Images && product.Images.map((image, idx) => {
                                                                return (

                                                                    <div onClick={() => this.setState({ previewImage: image })} className={this.state.previewImage == image ? "image active" : "image"}>
                                                                        <img draggable="false" src={image} />
                                                                    </div>


                                                                )
                                                            })
                                                        }






                                                    </div>

                                                </Col>
                                                <Col lg="7">

                                                    <h2>{product.Name && product.Name}</h2>


                                                    <div className="price">
                                                        <span>{this.state.package && this.state.package.price && this.state.package.price.formatPrice(this.props.currency)}</span>

                                                    </div>


                                                    <div className="spacer"></div>
                                                   {/* <p className="short-description">
                                                        Vratite svojoj koži prirodni sjaj i mladalački izgled uz Anti age EGZOTIK kremu.</p>

                                                    <div className="spacer"></div>*/}


                                                    <div className="packages-title">
                                                        <h6>Pakovanje:</h6>
                                                        <button className="wishlist-btn" onClick={this.addToWishlist}><Isvg src={wishlistIcon} /> Dodaj u listu omiljenih</button>

                                                    </div>
                                                    <div className="packages">

                                                        {
                                                            product.package && product.package.map((item, idx) => {
                                                                return (
                                                                    <div key={idx} onClick={() => this.setState({ package: item })} className={this.state.package && this.state.package.name == item.name ? 'package active' : 'package'}>
                                                                        <p>{item.name}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>

                                                    <div className="spacer"></div>
                                                    {product.StockLevel > 0 && this.state.package && this.state.package.price ? <div className="buttons">
                                                        <div className="detail-quantity">
                                                            <h6>Količina</h6>
                                                            <div>
                                                                <button onClick={() => {
                                                                    if (this.state.quantity -1 >= product.MinOrder){
                                                                        this.setState({
                                                                            quantity: this.state.quantity -1
                                                                        })
                                                                    }
                                                                }}><Isvg src={minus_icon} /></button>
                                                                <h6>{this.state.quantity}</h6>
                                                                <button onClick={() => {
                                                                        this.setState({
                                                                            quantity: this.state.quantity + 1
                                                                        })
                                                                    
                                                                }}><Isvg src={plus_icon} /></button>
                                                            </div>
                                                        </div>
                                                        <button className="button button-black" onClick={this.addToCart}>{this.props.translate('Dodaj u korpu')}</button>
                                                    </div>
                                                        : null}


                                                </Col>
                                                <Col lg="12" className="about-product-title">
                                                    <div >
                                                        <h6>O proizvodu</h6>
                                                    </div>
                                                </Col>
                                                <Col dangerouslySetInnerHTML={{ __html: product.Description }} lg="12" className="product-description">
                                                </Col>

                                            </Row>
                                        </Container>




                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                    </Row>
                </Container>
                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                            <div className="title-leafs">
                                    <Isvg src={titleLeafs} />
                                </div>

                                <h2>
                                Proizvodi na akciji
                                </h2>
                            </Col>

                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.newestProducts.map((item, idx) => {
                                            return (
                                                <Article
                                                    _id={item._id}
                                                    alias={item.Alias}
                                                    image={item.Images && item.Images.length && item.Images[0]}
                                                    title={item.Name}
                                                    package={item.package}
                                                    currency={this.props.currency}
                                                    discount={item.discount}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}>
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col>


                        </Row>
                    </Container>
                </section>


                <Newsletter {...this.props} {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(DetailPage));
