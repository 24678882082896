import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';
import ReactPaginate from 'react-paginate';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import ListArticle from '../components/listArticle';

import Categories from '../components/categories';

import grid_icon_active from '../assets/svg/grid-view-active.svg';
import list_icon from '../assets/svg/list-view.svg';

import grid_icon from '../assets/svg/grid-view.svg';
import list_icon_active from '../assets/svg/list-view-active.svg';
import titleLeafs from '../assets/svg/title-leafs.svg'

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


class CategoryPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.sortArticles = this.sortArticles.bind(this);

        this.state = {
            display: 'grid',
            breadcrumb: [],
            articles: [],
            total: 0,
            page: 0,
            sort: props.translate('Relevantnosti'),
            recentArticles: [
            ]
        };

    }


    componentDidMount() {
        if (this.props.socketIOClient && this.props.categories.length) {
            this.registerSocketIOEvents();
        }

    }

    sortArticles(sort) {
        this.setState({
            sort: sort
        }, () => {
            this.fetchProducts();
        });


    }

    fetchProducts() {
        console.log(this.state.page);
        let path = this.props[0].location.pathname;
        let brokenPath = path.split("&");
        let category = brokenPath[0].replace("/category", "");
        let filters = {};

        for (let i = 1; i < brokenPath.length; i++) {
            let brokenFilter = brokenPath[i].split("=");
            filters[brokenFilter[0]] = brokenFilter[1];
        }

        let sort = 0;
        if (this.state.sort == 'Relevantnosti') {
            sort = 0;
        } else if (this.state.sort == 'Imenu') {
            sort = 1;
        } else if (this.state.sort == 'Cijena: Opadajuće') {
            sort = 2;
        } else if (this.state.sort == 'Cijena: Rastuće') {
            sort = 3;
        }

        this.props.socketIOClient.emit('fetchProducts', { category: category, skip: filters.page ? (parseInt(filters.page) - 1) * 12 : 0, search: filters.search ? filters.search : null, count: 12, sort: sort });

        let breadcrumb = this.generateBreadcrumb(this.props.categories, category, 0);

        if (!filters.page) {
            this.setState({
                page: 0
            }, () => {
                this.forceUpdate();
            })
        }

        if (breadcrumb) {
            for (let i = 0; i < breadcrumb.length; i++) {
                let state = {};
                state['_selectedCategory' + i] = breadcrumb[i]._id;
                this.setState(state);
                console.log(state);
            }
        }

        this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);

    }

    updateFilter(name, val) {
        let path = this.props[0].location.pathname;
        let brokenPath = path.split("&");

        let filters = {};

        for (let i = 1; i < brokenPath.length; i++) {
            let brokenFilter = brokenPath[i].split("=");
            filters[brokenFilter[0]] = brokenFilter[1];
        }

        filters[name] = val;

        let link = brokenPath[0];

        if (link == "/category") {
            link = "/category/";
        }
        for (var key in filters) {
            if (filters.hasOwnProperty(key)) {
                link += "&" + key + "=" + filters[key];
            }
        }

        if (!filters.page) {
            this.setState({
                page: parseInt(filters.page) - 1
            }, () => {
                this.forceUpdate();
            })
        }


        this.props[0].history.push(link)

    }

    componentDidUpdate(prevProps) {


        if (this.props.socketIOClient && this.props.categories.length) {
            this.registerSocketIOEvents();
        }

        //console.log(this.props[0]);
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.fetchProducts();
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('fetchProducts', (data) => {
            console.log(data);
            this.setState({
                articles: data.products,
                total: data.total

            });
        });

        this.fetchProducts();
    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchCategories");
    }


    generateBreadcrumb(items, breadcrumb, level) {
        if (breadcrumb[0] == "/") {
            breadcrumb = breadcrumb.substr(1);
        }
        let broken = breadcrumb.split("/");
        let arr = [];

        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            console.log(broken, items[i], level);
            if (broken[level] == items[i].Alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].Name,
                    link: "/category/" + items[i].Alias
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }

    }



    render() {
        console.log(this.props.breadcrumb);

        return (
            <div className="category-wrap">


                <Container>
                    <Row>
                    <Col lg="3" className="d-none d-lg-block">
                            <div className="categories">
                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />




                            </div>
                        </Col>

                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                            {this.props[0].location.pathname.indexOf('search') !== -1 ? <li><Link to='/category'>{this.props.translate('Rezultati pretrage')}</Link></li> : <li><Link to='/category'>{this.props.translate('Kategorija')}</Link></li>}
                                            {
                                                this.props.breadcrumb.map((item, idx) => {
                                                    return (
                                                        <li key={idx}><Link to={item.link}>{item.name}</Link></li>

                                                    )
                                                })
                                            }
                                        </ul>
                                    </Col>
                                    <Col lg="5">
                                        {this.props[0].location.pathname.indexOf('search') !== -1 ?
                                            <h1> {this.props.translate("REZULTATI PRETRAGE")}</h1>
                                            :
                                            <h1>{this.props.breadcrumb.length ? this.props.breadcrumb[this.props.breadcrumb.length - 1].name : "KATEGORIJE"}</h1>

                                        }
                                    </Col>
                                    <Col lg="7" >
                                        <div className="sort-form">
                                            <span>SORTIRAJ PO</span>
                                            <Dropdown className="select-field" isOpen={this.state.dropdownOpen} toggle={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }}>
                                                <DropdownToggle nav caret>
                                                    {this.props.translate(this.state.sort)}
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-animation">
                                                    <DropdownItem onClick={() => this.sortArticles('Relevantnosti')}>{this.props.translate('Relevantnosti')}</DropdownItem>
                                                    <DropdownItem onClick={() => this.sortArticles('Imenu')}>{this.props.translate('Imenu')}</DropdownItem>
                                                    <DropdownItem onClick={() => this.sortArticles('Cijena: Opadajuće')}>{this.props.translate('Cijena: Opadajuće')}</DropdownItem>
                                                    <DropdownItem onClick={() => this.sortArticles('Cijena: Rastuće')}>{this.props.translate('Cijena: Rastuće')}</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                            <div className={this.state.display === 'grid' ? 'btn active' : 'btn'} onClick={() => { this.setState({ display: 'grid' }) }}>
                                                <Isvg src={this.state.display === 'grid' ? grid_icon_active : grid_icon} />
                                            </div>
                                            <div className={this.state.display === 'list' ? 'btn active' : 'btn'} onClick={() => { this.setState({ display: 'list' }) }}>
                                                <Isvg src={this.state.display === 'list' ? list_icon_active : list_icon} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <h2>
                                        </h2>
                                    </Col>
                                    {
                                        this.state.articles.map((item, idx) => {
                                            /*if (!item.price){
                                                return null;
                                            }*/
                                            if (this.state.display === 'grid') {
                                                return (
                                                    <Col lg="4" sm="6" xs="6" className="article-container">
                                                        <Article

                                                            _id={item._id}
                                                            alias={item.Alias}
                                                            discount={item.discount}
                                                            image={item.Images && item.Images.length && item.Images[0]}
                                                            title={item.Name}
                                                            package={item.package}
                                                            currency={this.props.currency}
                                                            price={item.price ? item.price : 0}
                                                            inStock={item.StockLevel > 0 ? true : false}
                                                            translate={this.props.translate}>
                                                        </Article>
                                                    </Col>
                                                )
                                            } else {
                                                return (
                                                    <Col lg="12" sm="6" xs="6">
                                                        <ListArticle

                                                            _id={item._id}
                                                            alias={item.Alias}
                                                            discount={item.discount}
                                                            image={item.Images && item.Images.length && item.Images[0]}
                                                            title={item.Name}
                                                            package={item.package}
                                                            currency={this.props.currency}

                                                            price={item.price ? item.price : 0}
                                                            inStock={item.StockLevel > 0 ? true : false}
                                                            translate={this.props.translate}>
                                                        </ListArticle>
                                                    </Col>
                                                )
                                            }
                                        })
                                    }

                                    <Col lg="12">
                                        <ReactPaginate
                                            previousLabel={''}
                                            nextLabel={''}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.total / 12}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={2}
                                            onPageChange={(page) => { this.updateFilter('page', page.selected + 1) }}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            forcePage={this.state.page}
                                        />

                                    </Col>
                                </Row>
                            </Container>

                        </Col>

                    </Row>
                </Container>

                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12">
                            <div className="title-leafs">
                                    <Isvg src={titleLeafs} />
                                </div>

                                <h2>
                                Proizvodi na akciji
                                                                </h2>
                            </Col>
                            {
                                this.props.newestProducts.map((item, idx) => {
                                    /*if (!item.price){
                                        return null;
                                    }*/
                                    return (
                                        <Col lg="3" sm="6" xs="6" key={idx} className="article-container1">
                                            <Article
                                                _id={item._id}
                                                alias={item.Alias}
                                                discount={item.discount}
                                                image={item.Images && item.Images.length && item.Images[0]}
                                                title={item.Name}
                                                price={item.price ? item.price : 0}
                                                inStock={item.StockLevel > 0 ? true : false}
                                                translate={this.props.translate}>
                                            </Article>
                                        </Col>
                                    )
                                })
                            }

                        </Row>
                    </Container>
                </section>

                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(CategoryPage));
